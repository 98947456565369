<template>
  <div>
    <b-container class="mt-5">
      <b-form @submit.prevent="changePassword" @reset="limpiar">
        <b-form-group
          id="input-group-1"
          label="Password anterior"
          label-for="input-1"
        >
          <b-form-input
            id="input-1"
            v-model="oldPassword"
            type="text"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Nuevo password"
          label-for="input-2"
        >
          <b-form-input id="input-2" v-model="password" required></b-form-input>
        </b-form-group>

        <b-form-group
          id="input-group-2"
          label="Verifica nuevo password"
          label-for="input-3"
        >
          <b-form-input
            id="input-3"
            v-model="verifyPassword"
            required
          ></b-form-input>
        </b-form-group>

        <b-button type="submit" variant="primary" class="mr-5"
          >Cambiar password</b-button
        >
        <b-button type="reset" variant="danger">Limpiar</b-button>
      </b-form>
    </b-container>
  </div>
</template>

<script>
import { axiosInstance } from "../../utils/axiosInstance";
export default {
  data() {
    return {
      oldPassword: null,
      password: null,
      verifyPassword: null,
    };
  },
  methods: {
    async changePassword() {
      if (this.password != this.verifyPassword) {
        this.makeToast(
          "Password",
          "Los password no coinciden",
          "warning",
          2000
        );
        return;
      }

      try {
        await axiosInstance.post(
          "newPassword",
          {
            password: this.password,
            oldPassword: this.oldPassword,
          }
        );
        this.limpiar();
        this.makeToast(
          "Cambio de password",
          "Se ha cambiado el password con éxito",
          "success",
          3000
        );
      } catch (error) {
        this.makeToast(
          "Error al cambiar password",
          "La contraseña anterior es incorrecta",
          "danger",
          2000
        );
      }
    },
    makeToast(title = null, body = null, variant = null, duration = 1000) {
      body = body || "Toast body content";
      this.$bvToast.toast(body, {
        title: title || "default",
        toaster: "b-toaster-top-center",
        variant: variant || "default",
        autoHideDelay: duration,
        solid: true,
      });
    },
    limpiar() {
      this.oldPassword = null;
      this.password = null;
      this.verifyPassword = null;
    },
  },
};
</script>
